import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-responsive-modal';

export default class Addresses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            senderEmailAddress: '',
            existingAddresses: [],
            isConfirmationModalOpen: false
        };
    }

    componentDidMount() {
        this.props.server.getEmailAddressesForSending().then(emails => {
            this.setState({
                existingAddresses: emails
            });
        });
    }

    emailsChanged = (e) => {
        const senderEmailAddress = e.target.value;

        this.setState({ senderEmailAddress: senderEmailAddress });

        if (this.state.existingAddresses.includes(senderEmailAddress)) {
            this.emailInput.setCustomValidity("Sender email already exists");
        } else {
            this.emailInput.setCustomValidity("");
        }
    };

    hideConfirmationModal = () => {
        this.setState({ isConfirmationModalOpen: false });
    };

    showConfirmationModal = event => {
        event.preventDefault();

        this.setState({ isConfirmationModalOpen: true });
    };

    onNewSenderAddressConfirmed = event => {
        event.preventDefault();

        this.setState({
            isConfirmationModalOpen: false,
            emails: ""
        });

        const { senderEmailAddress } = this.state;
        const { server } = this.props;

        const toastStyle =
        {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: true,
            closeButton: false
        };

        server.addNewSenderAddress(senderEmailAddress)
              .then(() => toast.success('Sender address added', toastStyle))
              .then(() => this.emailInput.focus());
    }

    render () {
        const { senderEmailAddress, isConfirmationModalOpen } = this.state;

        return (
            <div>
                <form className='react-form' onSubmit={this.showConfirmationModal} >
                    <h1>Add new sender email address</h1>
                    <div className='form-wrapper'>

                        <fieldset className='form-group'>
                            <p>Any new sender email address must first be approved by IT, so that they can either:</p>
                            <ul>
                                <li>Add a new mailbox</li>
                                <li>Add a new domain</li>
                            </ul>

                            <p>If the client wants us to send from their domain, <a href='https://morar.freshdesk.com/solution/articles/8000048901-sending-on-behalf-of-a-client-s-email-address'>they need to set up an SPF record</a></p>
                        </fieldset>

                        <fieldset className='form-group'>
                            <label htmlFor='formAddress'>Sender address:</label>
                            <input
                                id='formAddress'
                                className='form-input'
                                type='email'
                                placeholder='eg surveys@savanta-mail.com'
                                required
                                autoFocus
                                value={senderEmailAddress}
                                onChange={this.emailsChanged}
                                ref={(input) => { this.emailInput = input; }} />
                        </fieldset>

                        <div className='button-wrapper'>
                            <input className='btn' type='submit' value="Add sender address" />
                        </div>
                    </div>
                </form>
                <Modal
                    open={isConfirmationModalOpen}
                    onClose={this.hideConfirmationModal}
                    center
                    classNames={{ overlay: 'custom-overlay', modal: 'custom-modal' }}>
                    <h2>Add new sender address?</h2>
                    <p>Are you sure you want to add <strong>{senderEmailAddress}</strong> as a new sender address? </p>
                    <div className='close-button-wrapper'>
                        <input className='btn primary' type='button' value="Add" onClick={this.onNewSenderAddressConfirmed}/>
                        <input className='btn secondary' type='button' value="Cancel" onClick={this.hideConfirmationModal} />
                    </div>
                </Modal>
            </div>
        );
    }
}