import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Server from './ServerApi/Server';
import App from './App';
import { runWithAdal } from 'react-adal';
import { authContext } from './AdalConfig';

runWithAdal(authContext, () => {
    const server = new Server(authContext, "/");
    ReactDOM.render(<App server={server} />, document.getElementById('root'));
});