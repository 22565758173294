import axios from 'axios';
import { adalGetToken } from 'react-adal';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import './server.css'

class Server {
    constructor(authContext, serverUrl) {
        this.serverUrl = serverUrl || '';
        this.authContext = authContext;

        axios.interceptors.response.use((response) => {
            return response;
        }, function (error) {
            toast.error(`${error.message}. ${error.response ? error.response.data : ''}`, { position: toast.POSITION.BOTTOM_CENTER, autoClose: false, closeButton: true });

            return Promise.reject(error.response);
        });
    }

    getEmailAddressesForSending() {
        return this.getAxiosConfig()
            .then(config => axios.get('api/email/addresses', config))
            .then(response => response.data);
    }

    unsubscribe(commaSeparatedEmailAddresses) {
        let data =
        {
            commaSeparatedEmailAddresses: commaSeparatedEmailAddresses
        };

        return this.getAxiosConfigWithAntiforgery().then(config => axios.post('api/email/unsubscribe', data, config))
    }

    addNewSenderAddress(emailAddress) {
        let data =
        {
            emailAddress: emailAddress
        };

        this.getAxiosConfigWithAntiforgery()
            .then(config => axios.post('api/email/addresses', data, config))
            .then(result => {
                return result;
            });
    }

    uploadFile(data) {
        const uploadingToastId = toast.loading('Uploading...', { position: toast.POSITION.BOTTOM_CENTER, autoClose: false, closeButton: false});
        this.getAxiosConfig().
            then(config => axios.post('api/messagingPlatformStats/workbook/uploaddocument', data, config)).then(result => {

                const textToDisplay = result.data;
                toast.update(uploadingToastId, {
                    render: textToDisplay,
                    type: "success",
                    className: "toastMultiLineMessage",
                    isLoading: false,
                    closeButton: true 
                });
                return result
            }).catch(x => {
                toast.update(uploadingToastId, {
                    render: "Upload failed",
                    type: "error",
                    isLoading: false,
                    closeButton: true
                });

            });
    }
    getCrowdologyWorkbook() {
        return this.getAxiosConfigForExcelDownload()
            .then(config => axios.get('api/messagingPlatformStats/workbook/crowdology', config))
            .then(response => this.saveExcel(response, "CrowdologyMessagingStats - Private.xlsx"));
    }
    getRespondentsNotEmailValidated() {
        return this.getAxiosConfigForExcelDownload()
            .then(config => axios.get('api/messagingPlatformStats/workbook/EmailsForValidation', config))
            .then(response => this.saveExcel(response, "EmailsForValidation - Private.xlsx"));

    }

    getMyVueWorkbook() {
        return this.getAxiosConfigForExcelDownload()
            .then(config => axios.get('api/messagingPlatformStats/workbook/myVue', config))
            .then(response => this.saveExcel(response, "MyVueMessagingStats - Private.xlsx"));
    }

    getCaffeNeroWorkbook(startDate, endDate) {
        return this.getAxiosConfigForExcelDownload()
            .then(config => axios.get(`api/messagingPlatformStats/workbook/caffeNero?startDate=${startDate}&endDate=${endDate}`, config))
            .then(response => this.saveExcel(response, "CaffeNeroSendStats - Private.xlsx"));
    }

    saveExcel(response, defaultFilename) {
        const file = new Blob([response.data], {type: "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        const fileName = this.getFilenameFromHeader(response.headers["Content-Disposition"], defaultFilename);
        saveAs(file, fileName);
    }

    getFilenameFromHeader(headerString, defaultFilename) {
        if (headerString && headerString.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(headerString);
            if (matches != null && matches[1]) {
                return matches[1].replace(/['"]/g, '');
            }
        }

        return defaultFilename;
    }

    logOut() {
        this.authContext.logOut();
    }

    getAxiosConfig() {
        return adalGetToken(this.authContext, this.authContext.config.loginResource)
            .then(token => {
                return {
                    baseURL: this.serverUrl,
                    headers: { 'Authorization': "bearer " + token }
                }
            });
    }

    getAxiosConfigWithAntiforgery() {
        return this.getAxiosConfig()
            .then(config => axios.get('api/antiforgery/token', config)
                .then(response => {
                    const xsrfToken = document.cookie
                        .split('; ')
                        .find(row => row.startsWith('X-CSRF-C='))
                        .split('=')[1];

                    var headers = config.headers;
                    headers['X-CSRF-H'] = xsrfToken;
                    return config;
                }));
    }

    getAxiosConfigForExcelDownload() {
        return this.getAxiosConfig()
            .then(config => {
                var headers = config.headers;
                headers['Content-Disposition'] = 'attachment';
                headers['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                config.responseType = 'arraybuffer';
                return config;
            });
    }
}

export default Server;
