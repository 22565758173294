import { AuthenticationContext } from 'react-adal';

export const adalConfig = {
    tenant: 'c880139e-fd32-4303-a6c4-3c775406e4c5',
    clientId: 'c17b0db1-d194-44d2-afcf-a2e73a8dd813',
    endpoints: {
        api: 'c880139e-fd32-4303-a6c4-3c775406e4c5',
    },
    cacheLocation: 'localStorage',
};
export const authContext = new AuthenticationContext(adalConfig);