import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-responsive-modal';

class Unsubscribe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emails: '',
            isUnsubscribeModalOpen: false
        };
    }
    emailsChanged = (e) => {
        this.setState({ emails: e.target.value });
    };
    onSubmit = event => {
        event.preventDefault();

        this.setState({ isUnsubscribeModalOpen: true });
    };
    onUnsubscribeConfirmed = event => {
        event.preventDefault();

        this.setState({
            isUnsubscribeModalOpen: false,
            emails: ""
        });

        const { emails } = this.state;
        const { server } = this.props;

        const toastStyle =
        {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: true,
            closeButton: false
        };

        server.unsubscribe(emails)
            .then(response => {
                if (response && response.status === 200) {
                    toast.success(response.data, toastStyle);
                }
            })
            .then(() => this.emailInput.focus())
    };
    hideUnsubscribeModal = () => {
        this.setState({ isUnsubscribeModalOpen: false });
    };

    render() {
        const { emails, isUnsubscribeModalOpen } = this.state;

        return (
            <div>
                <form className='react-form' onSubmit={this.onSubmit}>
                    <h1>Global unsubscribe</h1>
                    <div className='form-wrapper'>

                        <fieldset className='form-group'>
                            <label htmlFor='formEmails'>Email addresses</label>
                            <input
                                id='formEmails'
                                className='form-input'
                                type='text'
                                placeholder='eg alice@gmail.com,bob@gmail.com'
                                required
                                autoFocus
                                value={emails}
                                onChange={this.emailsChanged}
                                ref={(input) => { this.emailInput = input; }} />
                        </fieldset>

                        <div className='button-wrapper'>
                            <input className='btn' type='submit' value="Unsubscribe..." />
                        </div>
                    </div>
                </form>
                <Modal
                    open={isUnsubscribeModalOpen}
                    onClose={this.hideUnsubscribeModal}
                    center
                    classNames={{ overlay: 'custom-overlay', modal: 'custom-modal' }}>
                    <h2>Global unsubscribe?</h2>
                    <p>Are you sure you want to globally unsubscribe <strong>{emails.replaceAll(",", ", ")}</strong> from all Savanta mailing platform email campaigns?</p>
                    <div className='close-button-wrapper'>
                        <input className='btn primary' type='button' value="Unsubscribe" onClick={this.onUnsubscribeConfirmed} />
                        <input className='btn secondary' type='button' value="Cancel" onClick={this.hideUnsubscribeModal} />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Unsubscribe;
