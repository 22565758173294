import React, { Component } from 'react';
import HeaderNavBar from './HeaderNavBar';
import Unsubscribe from './Unsubscribe/Unsubscribe';
import Addresses from './Addresses/Addresses';
import MessagingPlatformStats from './MessagingPlatformStats/MessagingPlatformStats';
import EmailValidation from './EmailValidation/EmailValidation';
import {HashRouter as Router, Redirect, Route} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './Modal.css'

class App extends Component {
  render() {
    const { server } = this.props;

    return (
      <Router>
        <div>
          <HeaderNavBar server={server} />
          <div className="scroll-bar-compensator">
            <Route path="/" exact render={() => <Redirect to="/Unsubscribe"/>} />
            <Route path="/Unsubscribe" render={(props) => <Unsubscribe server={server} />} />
            <Route path="/Addresses" render={props => <Addresses server={server} />} />
            <Route path="/MessagingPlatformStats" render={props => <MessagingPlatformStats server={server} />} />
            <Route path="/EmailValidation" render={props => <EmailValidation server={server} />} />
            <ToastContainer hideProgressBar={true} toastClassName={'toast'} autoClose={2000} />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
